import {useState} from "react";

import "./Counter.css";
import NumberInput from "../numberinput/NumberInput";

function Counter() {
    const [count, setCount] = useState(0);
    const [nice, setNice] = useState(false);

    const handleClick = (event) => {
        let newCountStateValue;
        switch (event.target.name) {
            case "add":
                newCountStateValue = count + 1;
                break;
            case "sub":
                newCountStateValue = count - 1;
                break;
            case "res":
                newCountStateValue = 0;
                break;
            default:
                console.error(`Llega a handleClick ${event.target.name} no considerado...`);
        }
        handleValueChange(newCountStateValue);
    }

    const handleValueChange = (value) => {
        checkNiceness(value);
        setCount(value);
    }

    const checkNiceness = (value) => {
        value = Math.abs(value);
        const nice = value === 69 || value === 420;
        setNice(nice);
    }

    const renderValueParagraph = () => {
        if (count && count !== 0) {
            return <p>En estos momentos, valgo <span>{count}</span>...</p>
        } else {
            return <p>En estos momentos, no valgo nada...</p>
        }
    }
    return (
        <div className="counter">
            <header>
                <h4>Soy un contador</h4>
            </header>
            {renderValueParagraph()}
            <NumberInput value={count} callback={handleValueChange} />
            <div className="button-wrap">
                <button name="add" onClick={handleClick}>Incrementar</button>
                <button name="sub" onClick={handleClick}>Restar</button>
                <button name="res" onClick={handleClick}>Resetear</button>
            </div>
            {
                nice
                    ? <p>Nice</p>
                    : <></>
            }
        </div>
    );
}

export default Counter;