import "./NumberInput.css";
import {useState} from "react";

function NumberInput(props) {
const [valid, setValid] = useState(true);
    /**
     * Changes the input if it is a number.
     * If a "-" or "+" is detected, the sign of the input is changed
     * @param event
     */
    const handleInputChange = (event) => {
        const rawValue = event.target.value;
        const changeSign = (rawValue.indexOf("-") !== -1 && props.value > 0)
            || (rawValue.indexOf("+") !== -1 && props.value < 0);

        if (rawValue === "" || rawValue === "-") {
            props.callback(0);
            setValid(true);
        } else if (changeSign) {
            props.callback(props.value * -1);
            setValid(true);
        } else {
            const parsedInput = Number(rawValue);
            if (!isNaN(parsedInput)) {
                props.callback(parsedInput);
                setValid(true);
            } else {
                setValid(false);
                setTimeout(() => setValid(true), props.invalidTimeoutMs || 500);
            }
        }
    }

    /**
     * Listens for an action on certain keystrokes
     * @param event
     */
    const listenForConfiguredEvents = (event) => {
        switch (event.key) {
            case "Delete":
                props.callback(0);
                setValid(true);
                break;
            default:
                break;
        }

    }
    return (
        <div className="numeric_input">
        <input type="text" className={(valid && (false || props.highlightValidToo)) ? "valid" : ((! valid) ? "invalid" : "")} value={props.value} onChange={handleInputChange}
               onKeyDown={listenForConfiguredEvents}/>
        </div>
    );
}

export default NumberInput;