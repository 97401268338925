import './App.css';
import Counter from "./components/counter/Counter";

function App() {
    return (
        <div className="App">
            <header className="App-header">
                <h2>Welcome to App</h2>
                <p>Let's stick to <span>React</span></p>
            </header>
            <Counter/>
        </div>
);
}

export default App;
